<template>
    <div>
        <div class="v-application">

            <div class="mb-4">
                <h2 class="inline-block">{{ $t('component_title')}}</h2>
                <v-btn
                    outlined
                    small
                    class="ml-4 inline-block"
                    color="primary"
                    style="background-color: #fff"
                    @click="$emit('toggleMode')"
                >
                    <v-icon left>mdi-arrow-left</v-icon><strong>{{ $t('component_title_back')}}</strong>
                </v-btn>
            </div>

            <v-card>
                <v-card-text>
                    <v-row :class="{'has-no-error': !hasError, 'has-error': hasError}">
                        <v-col :cols="12">
                            <v-text-field
                                outlined
                                hide-details
                                class="text-h5"
                                :label="$t('component_titlePlaceholder')"
                                :placeholder="$t('component_titlePlaceholder')"
                                @keyup="$refs.templateErrorMessage.hideLabelMessage(); hasError = false"
                                v-model="titleValue"
                            />

                            <div class="pt-2" style="height: 34px;">
                                <label-message class="text-center" ref="templateErrorMessage"/>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>


            <v-row class="mt-8">
                <v-col :cols="4">
                    <v-card class="section-container">
                        <v-card-text>
                            <h5 class="text-h5 font-weight-bold ml-6">{{ $t('component_indicatorsTitle')}}</h5>
                            <v-list class="mt-6">
                                <v-list-group v-for="section in templateComponents" :key="section.name" :ripple="false" ref="section">
                                    <template v-slot:activator>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon v-text="section.icon"></v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title v-text="section.name" class="text-h6"></v-list-item-title>
                                        </v-list-item>
                                    </template>
                                    <v-list v-for="component in section.components" :key="component.name" :class="[component.selected ? 'primary' : 'grey lighten-4']" class="pa-0 ml-8 my-1">
                                        <v-list-item :class="[component.selected ? 'white--text' : 'grey lighten-4 grey--text text--darken-2']">
                                            <v-tooltip v-if="getCategory(component.category).name" bottom open-delay="600">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-list-item-icon
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <v-icon :class="[component.selected ? 'white--text' : 'grey lighten-4 grey--text text--darken-2']" size="16" v-text="getCategory(component.category).icon"></v-icon>
                                                    </v-list-item-icon>
                                                </template>
                                                <span>{{ getCategory(component.category).name }}</span>
                                            </v-tooltip>
                                            <v-list-item-icon v-else>
                                                <v-icon :class="[component.selected ? 'white--text' : 'grey lighten-4 grey--text text--darken-2']" size="16" v-text="getCategory(component.category).icon"></v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title v-text="component.displayName"></v-list-item-title>
                                            <div class="my-1 pt-1">
                                                <v-tooltip bottom open-delay="600">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <div v-html="getComponentIcon(component.width)" v-bind="attrs" v-on="on"/>
                                                    </template>
                                                    <span v-html="$t('component_widthPercent').replace('[*WIDTH*]', getComponentWidthInPercent(component.width))"></span>
                                                </v-tooltip>
                                            </div>
                                            <div v-if="component.selected">
                                                <v-tooltip bottom open-delay="600">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon color="primary" @click="removeComponentFromLayout(component.name)" small
                                                               dark
                                                               v-bind="attrs"
                                                               v-on="on"
                                                        >
                                                            <v-icon class="white--text">mdi-minus-circle</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('component_deleteComponent') }}</span>
                                                </v-tooltip>
                                            </div>
                                            <div v-else>
                                                <v-tooltip bottom open-delay="600">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon @click="addComponentToLayout(component.name)" small
                                                               color="primary"
                                                               dark
                                                               v-bind="attrs"
                                                               v-on="on"
                                                        >
                                                            <v-icon>mdi-plus-circle</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('component_addComponent') }}</span>
                                                </v-tooltip>
                                            </div>
                                        </v-list-item>
                                    </v-list>
                                </v-list-group>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>


                <v-col :cols="8">
                    <v-card style="min-height: 900px">
                        <v-card-text>
                            <v-row>
                                <v-col :cols="6">
                                    <h5 class="text-h5 font-weight-bold">{{ $t('component_structureTitle')}}</h5>
                                </v-col>
                                <v-col class="text-right" :cols="6">
                                    <v-btn
                                        color="white"
                                        class="primary--text"
                                        @click="displayPreview"
                                    >
                                        <v-icon left>mdi-eye-outline</v-icon>
                                        {{ $t('component_previewModel') }}
                                    </v-btn>

                                    <v-btn
                                        color="primary"
                                        class="ml-4"
                                        @click="saveTemplate"
                                    >
                                        <v-icon left>mdi-content-save</v-icon>
                                        {{ $t('component_saveModel') }}
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <div class="pt-2 pb-8" style="height: 34px;">
                                <label-message class="text-center" ref="templateComponentsErrorMessage"/>
                            </div>

                            <div class="layout-template-builder">
                                <grid-layout
                                    :layout.sync="layout"
                                    :col-num="colNum"
                                    :row-height="100"
                                    :is-draggable="true"
                                    :is-resizable="false"
                                    :is-mirrored="false"
                                    :margin="[10, 15]"
                                    :use-css-transforms="true"
                                    class="grid"
                                >
                                    <grid-item
                                        v-for="item in layout"
                                        :x="item.x"
                                        :y="item.y"
                                        :w="item.w"
                                        :h="item.h"
                                        :i="item.i"
                                        :key="item.i"
                                    >
                                        <v-card class="h-full">
                                            <v-card-text>
                                                <div v-if="item.name === 'reportingBuilderCustomTitle'" class="px-8">
                                                    <v-icon style="position: absolute; left: 12px; top: 20px" color="grey" v-text="getCategory(item.category).icon"></v-icon>
                                                    <v-text-field
                                                        :placeholder="$t(`component_${item.name}_title`)"
                                                        outlined
                                                        dense
                                                        hide-details
                                                        :value="item.displayedData"
                                                        v-on:keyup="(event)=>item.displayedData = event.target.value"
                                                        class="font-weight-bold"
                                                    />
                                                </div>

                                                <div  v-else-if="item.name === 'reportingBuilderCustomText'" class="px-8">
                                                    <v-icon style="position: absolute; left: 12px; top: 20px" color="grey" v-text="getCategory(item.category).icon"></v-icon>
                                                    <v-textarea
                                                        :placeholder="$t(`component_${item.name}_title`)"
                                                        no-resize
                                                        outlined
                                                        :rows="6"
                                                        hide-details
                                                        :value="item.displayedData"
                                                        v-on:keyup="(event)=>item.displayedData = event.target.value"
                                                    />
                                                </div>

                                                <div v-else>
                                                    <h4>{{$t(`component_${item.name}_title`)}}</h4>
                                                </div>

                                                <p class="text-body-1">{{$t(`component_${item.name}_subtitle`)}}</p>
                                                <div
                                                    style="position: absolute;bottom: 42px; left: calc(50% - 26px)"
                                                    v-if="item.name !== 'reportingBuilderCustomText' && item.name !== 'reportingBuilderCustomTitle'"
                                                >
                                                    <v-icon style="font-size: 52px" color="grey" v-text="getCategory(item.category).icon"></v-icon>
                                                </div>
                                            </v-card-text>

                                            <v-tooltip bottom open-delay="600">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        icon
                                                        color="primary"
                                                        @click="removeComponentFromLayout(item.name, item.i)"
                                                        small
                                                        class="ml-4"
                                                        style="position: absolute; top: 10px; right: 10px"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <v-icon>mdi-minus-circle-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('component_deleteComponent') }}</span>
                                            </v-tooltip>

                                        </v-card>
                                    </grid-item>
                                </grid-layout>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

        </div>
        <reporting-preview-model ref="reportingPreview" :template-id="templateId" v-if="templateId && showPreview"/>
    </div>

</template>

<script>
import VueGridLayout from 'vue-grid-layout';
// import navMenuItems from "@/layouts/components/vertical-nav-menu/navMenuItems";
import route from "@/api/axios/dataReport/template";
import ReportingPreviewModel from "@/components/yooda-components/reporting/ReportingPreviewModel";
import {mapGetters} from "vuex";
import LabelMessage from "@/components/yooda-components/LabelMessage";
import Vue from "vue";
import reportingApiCalls from "@/api/axios/dataReport/reporting";

export default {
    name: "ReportingBuildercomponentsList",
    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        ReportingPreviewModel,
        LabelMessage
    },
    data() {
        return {
            titleValue:'',
            templateComponents: [],
            cptLayout:0,
            layout: [],
            colNum: 12,
            templateId: null,
            showPreview: false,
            hasError: false,
            navMenuItems:[
                {name:'dashboard', icon:'mdi-monitor-dashboard'},
                {name:'seo-visibility', icon:'mdi-seal'},
                {name:'advertising', icon:'mdi-bullseye'},
                {name:'market-segment', icon:'mdi-tag-multiple'},
                {name:'competitor', icon:'mdi-star'},
                {name:'traffic', icon:'mdi-account-group'},
                {name:'local-visibility', icon:'mdi-map-marker'},
                {name:'website-pages', icon:'mdi-sitemap'},
                {name:'linking', icon:'mdi-link-variant'},
                {name:'progression-axis', icon:'mdi-compass'},
            ]
        }
    },
    computed: {
        ...mapGetters({
            getActiveCustomerSubscription:"customersManager/getActiveCustomerSubscription",
            hasLinking:"customersManager/getHasLinking",
        }),
    },
    props: {
        selectedTemplateId: null,
        associateToReportingId: null,
        redirectToProjectId: null,
    },
    methods: {
        getCategory(category) {
            switch (category) {
                case 'chart': return {name: this.$t('component_chart'), icon: 'mdi-poll'}
                case 'table': return {name: this.$t('component_table'), icon: 'mdi-grid-large'}
                case 'title': return {icon: 'mdi-format-header-2'}
                case 'text': return {icon: 'mdi-text-long'}
            }
        },
        getComponentIcon(width){
            let svgContent = `<svg width="100" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">`
            switch (width){
                default: svgContent += ` <rect x=".5" y=".5" width="21.16" height="8" rx="4" fill="currentColor" stroke="currentColor"/> <rect x="24.66" y=".5" width="21.16" height="8" rx="4" fill="currentColor" stroke="currentColor"/> <rect x="48.82" y=".5" width="21.16" height="8" rx="4" fill="currentColor" stroke="currentColor"/>`; break;
                case 8: svgContent += `<rect x=".5" y=".5" width="21.16" height="8" rx="4" fill="currentColor" stroke="currentColor"/><rect x="24.66" y=".5" width="21.16" height="8" rx="4" fill="currentColor" stroke="currentColor"/><rect x="48.82" y=".5" width="21.16" height="8" rx="4" stroke="currentColor"/>`;break;
                case 6: svgContent += `<rect x=".5" y=".5" width="33.25" height="8" rx="4" fill="currentColor" stroke="currentColor"/><rect x="36.75" y=".5" width="33.25" height="8" rx="4" stroke="currentColor"/>`;break;
                case 4: svgContent += `<rect x=".5" y=".5" width="21.16" height="8" rx="4" fill="currentColor" stroke="currentColor"/><rect x="24.66" y=".5" width="21.16" height="8" rx="4" stroke="currentColor"/><rect x="48.82" y=".5" width="21.16" height="8" rx="4" stroke="currentColor"/>`;break;
                case 3: svgContent += `<rect x=".5" y=".5" width="15" height="8" rx="4" fill="currentColor" stroke="currentColor"/><rect x="18.5" y=".5" width="15" height="8" rx="4" stroke="currentColor"/><rect x="36.5" y=".5" width="15" height="8" rx="4" stroke="currentColor"/><rect x="54.5" y=".5" width="15" height="8" rx="4" stroke="currentColor"/>`;break;
            }
            svgContent += `</svg>`
            return svgContent
        },

        getComponentWidthInPercent(width){
            return Math.floor((width * 100) / this.colNum)
        },

        getSectionInfo(name) {
            let sectionInfo = {}

            if (name === 'free-text') { sectionInfo = {name: this.$t('component_freeText'),icon: 'mdi-format-text'} }
            this.navMenuItems.forEach((item)=>{
                if(item.name === name){
                    sectionInfo = {name: this.$t(`navigationMenu_link_title_${item.name.replace('-','_')}`), icon:item.icon }
                }
            })
            return sectionInfo
        },

        getSectionIndex(name){
            switch (name){
                case 'dashboard': return 1
                case 'seo-visibility': return 2
                case 'advertising': return 3
                case 'market-segment': return 4
                case 'competitor': return 5
                case 'traffic': return 6
                case 'local-visibility': return 7
                case 'website-pages': return 8
                case 'linking': return 9
                case 'progression-axis': return 10
                case 'free-text': return 11
            }
        },

        getComponentsList() {
            route.readComponents().then((result)=>{
                let formattedComponents = []
                result.content.forEach(data => {

                    if(data.section !== 'linking' || (this.hasLinking() && data.section === 'linking')){

                        data.displayName = this.$t(`component_${data.name}_title`)
                        if(!formattedComponents[data.section]) {
                            let sectionInfo = this.getSectionInfo(data.section)
                            formattedComponents[data.section] = {
                                name: sectionInfo.name,
                                icon: sectionInfo.icon,
                                components: [],
                                index: this.getSectionIndex(data.section)
                            }
                        }
                        formattedComponents[data.section].components.push({...data, ...{selected: false}})
                        formattedComponents[data.section].components.sort((a,b) =>{
                            return (a.displayName < b.displayName) ? -1 : (a.displayName > b.displayName) ? 1 : 0
                        })
                    }


                })
                this.templateComponents = Object.values(formattedComponents)

                this.templateComponents.sort((a,b) => {
                    return (a.index < b.index) ? -1 : (a.index > b.index) ? 1 : 0
                })
            })
        },

        getTemplateComponents(){
            route.readTemplateById(this.templateId).then((res)=>{
                this.titleValue = res.content.name
                res.content.templateComponents.map((item) => {
                    this.layout.push({
                        "x":item.posX,
                        "y":item.posY,
                        "w":item.component.width,
                        "h":item.component.name !== 'reportingBuilderCustomTitle' ? 2 : 1,
                        "i":item.id,
                        id:item.component.id,
                        name: item.component.name,
                        category: item.component.category,
                        section: item.component.section,
                        data: item.data,
                        displayedData: (item.data && item.data.string ? item.data.string : ''),
                    })

                    let components = this.findComponentsByName(item.component.name)

                    if (components[0]) {
                        if (components[0].section !== 'free-text') {
                            components.forEach(item => {item.selected = true})
                        }
                    }
                })
            })
        },

        findComponentsByName(name) {
            let components = []
            this.templateComponents.forEach(data => {
                if (data.components.find(item => item.name === name)) {
                    components.push(data.components.find(item => item.name === name))
                }
            })

            return components
        },

        addComponentToLayout(name) {
            let components = this.findComponentsByName(name)

            let component = components[0]

            if (component && component.selected === false) {
                components.forEach(item => {item.selected = true})

                if (component.section === 'free-text') {
                    component.selected = false
                }

                let posY = 0
                if (this.layout.length) { posY = this.layout[this.layout.length - 1].y + 1 }

                let posX = 0

                if (this.layout.length > 0) {
                    let lastComponent = this.layout.at(-1)

                    if (lastComponent.x + lastComponent.w + component.width <= this.colNum) {
                        posX = lastComponent.w + lastComponent.x
                        posY = lastComponent.y
                    }
                }

                this.cptLayout++
                this.layout.push({
                    "x":posX,
                    "y":posY,
                    "w":component.width,
                    "isBounded":true,
                    "h":component.name !== 'reportingBuilderCustomTitle' ? 2 : 1,
                    "i":this.cptLayout,
                    id:component.id,
                    name: component.name,
                    category: component.category,
                    section: component.section,
                    data: {},
                    displayedData: '',
                })
            }
        },

        removeComponentFromLayout(name, cptId) {
            if (name !== 'reportingBuilderCustomTitle' && name !== 'reportingBuilderCustomText') {
                let components = this.findComponentsByName(name)
                components.forEach(item => {item.selected = false})

                this.layout = this.layout.filter(data => data.name !== name)
            } else {
                this.layout = this.layout.filter(data => data.i !== cptId)
            }
        },

        async saveTemplate(emitEvent = true, fromPreview = false){
            this.$refs.templateErrorMessage.hideLabelMessage()
            this.$refs.templateComponentsErrorMessage.hideLabelMessage()

            if(this.titleValue.trim() !== ''){

                let title = this.titleValue.slice(0,254)

                let templateData = {"name": title,"editable": true,"provider": "subscriber"}
                let templateComponents = []
                let atLeastOneComponent = false

                this.layout.forEach((component)=>{
                    if(component.section !== 'free-text'){atLeastOneComponent = true}
                    templateComponents.push({
                        posX: component.x,
                        posY: component.y,
                        componentId: component.id,
                        data: component.section === 'free-text' ? {string: (component.displayedData === '' || !component.displayedData ? ' ' : component.displayedData)} : null
                    })
                })

                if(!atLeastOneComponent || templateComponents.length === 0){
                    this.$refs.templateComponentsErrorMessage.showLabelMessage(this.$t('component_indicators_label_error'))
                }
                else{
                    if (this.templateId) {
                        return route.updateTemplate(this.templateId,templateData).then(()=>{
                            return this.saveTemplateComponent(templateComponents,emitEvent).then(()=>{
                                if (!fromPreview) {
                                    this.associateToReporting()
                                }
                            })
                        })
                    } else {
                        return route.createTemplate(this.$route.params.subscriptionId, templateData ).then((res)=>{
                            this.templateId = res.content.id
                            return this.saveTemplateComponent(templateComponents,emitEvent).then(()=>{
                                if (!fromPreview) {
                                    this.associateToReporting()
                                }
                            })
                        })
                    }
                }
            }
            else{
                this.$refs.templateErrorMessage.showLabelMessage(this.$t('component_title_label_error'),'orange lighten-5', 'orange--text')
                this.hasError = true
            }
        },
        associateToReporting(){
            if(this.associateToReportingId){
                reportingApiCalls.updateReportingTemplate(this.associateToReportingId,this.templateId).then(()=>{
                    this.$router.push({
                        name: 'reporting',
                        params: {
                            projectId: this.redirectToProjectId,
                            subscriptionId: this.$route.params.subscriptionId,
                        }
                    })
                })
            }
        },

        saveTemplateComponent(templateComponents, emitEvent=false) {
            return route.saveTemplateComponent(this.templateId, templateComponents).then(() => { if (emitEvent) { this.$emit('toggleMode') }})
                .catch((error)=>{
                    if(error.response.data && error.response.data.content === 'Bad Request'){
                        this.$refs.templateComponentsErrorMessage.showLabelMessage(this.$t('component_indicators_label_error'))
                    }
                })
        },

        displayPreview() {
            this.$refs.templateErrorMessage.hideLabelMessage()
            if(this.titleValue.trim() !== ''){
                this.showPreview = false
                this.saveTemplate(false, true).then(() => {
                    this.showPreview = true
                    Vue.nextTick(() => {
                        this.$refs.reportingPreview.showPreview()
                    })
                })
            }
            else{
                this.$refs.templateErrorMessage.showLabelMessage(this.$t('component_title_label_error'))
            }
        },
    },
    beforeMount() {
        this.templateId = this.selectedTemplateId
        this.getComponentsList()
        if (this.templateId) {
            this.getTemplateComponents()
        }
    }
}
</script>

<style lang="scss">

.layout-template-builder{
    min-height: 820px;
    border:2px solid $primary !important;
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 12px;

}

.vue-grid-item.vue-grid-placeholder {
    background: #53B982;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.has-no-error {
    margin: 4px -12px -28px -12px;
    transition: margin 0.5s ease-in-out;
}

.has-error {
    transition: margin 0.5s ease-in-out;
}

.section-container {
    position: sticky;
    top: 100px;
    height: 740px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 14px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 14px 14px transparent;
        border: solid 4px transparent;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 14px 14px #bbbbbe;
        border: solid 4px transparent;
        border-radius: 14px;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }
}

</style>
