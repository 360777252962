<template>

    <v-row>
        <v-col cols="12">
            <reporting-template-list v-if="mode ==='list'" @toggleMode="openBuilder" />
            <reporting-builder
                v-else
                :selected-template-id="editTemplateId"
                :associate-to-reporting-id="associateToReportingId"
                :redirect-to-project-id="redirectToProjectId"
                @toggleMode="mode='list'"
            />
        </v-col>

    </v-row>

</template>


<script>

import ReportingBuilder from "@/components/yooda-components/reporting/ReportingBuilder.vue";
import ReportingTemplateList from "@/components/yooda-components/reporting/ReportingTemplateList.vue";

export default {
    data() {
        return {
            mode:'list',
            editTemplateId:null,
            associateToReportingId:null,
            redirectToProjectId:null
        }
    },
    computed:{

    },
    methods:{
        openBuilder(templateId=null){
            this.editTemplateId = templateId
            this.mode='edit'
        }
    },
    components: {
        ReportingTemplateList,
        ReportingBuilder
    },
    beforeMount() {

    },
    mounted() {
        if(this.$route.query.addToReporting){
            this.editTemplateId = null
            this.mode = 'edit'
            this.associateToReportingId = this.$route.query.addToReporting
            this.redirectToProjectId = this.$route.query.projectId
            this.$router.replace({'addToProject': null});
        }

        this.analyticsIdentify()
    }

}

</script>
