<template>
<div>
    <div class="v-application">
        <v-row>
            <v-col cols="12">
                <h4 class="text-h4 font-weight-black primary--text mb-2">{{ $t('component_reportingTemplateList_title') }}</h4>
                <h6 style="font-weight: 100">{{ $t('component_reportingTemplateList_subtitle') }}</h6>
            </v-col>
            <v-col class="text-left" cols="8">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="searchTemplate"
                            :placeholder="$t('component_reportingTemplateList_inputSearch_placeholder')"
                            dense
                            prepend-inner-icon="mdi-magnify"
                            outlined
                            hide-details
                            class="rounded-lg white"
                            style="max-width: 400px"
                        />
                    </v-col>
                    <v-col cols="3" style="max-width: 200px">
                        <v-checkbox
                            v-model="filterFavorite"
                            hide-details
                        >
                            <template v-slot:label>
                                {{ $t('component_reportingTemplateList_checkbox_favorite') }}
                                <v-icon class="ml-4" color="red">mdi-heart</v-icon>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-col cols="3" style="max-width: 180px">
                        <v-checkbox
                            class="mx-auto"
                            v-model="filterMyModels"
                            :label="$t('component_reportingTemplateList_checkbox_myModels')"
                            hide-details
                        >
                        </v-checkbox>
                    </v-col>

                    <v-col cols="4" style="max-width: 260px">
                        <v-checkbox
                            v-model="filterYoodaModels"
                            hide-details
                        >
                            <template v-slot:label>
                                {{ $t('component_reportingTemplateList_checkbox_yoodaModels') }}
                                <img src="@/assets/images/yooda_icon.png" class="ml-4" style="width: 22px">
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>


            </v-col>
            <v-col class="text-right" cols="4">
                <v-btn color="primary" large @click="$emit('toggleMode')">{{ $t('component_reportingTemplateList_btn_create') }}</v-btn>
            </v-col>
        </v-row>

        <v-row class="mt-12" v-if="templateList">
            <v-col cols="3" v-for="template in displayedTemplateList" :key="template.id">
                <v-card>
                    <v-card-text>
                        <h6 class="text-h6">
                            <v-icon class="mr-2" color="red" @click="updateFavorite(template,false)" v-if="template.favorite">mdi-heart</v-icon>
                            <v-icon class="mr-2" color="red" @click="updateFavorite(template,true)" v-else>mdi-heart-outline</v-icon>
                            <img src="@/assets/images/yooda_icon.png" class="mr-2" style="vertical-align:middle;width: 22px" v-if="template.provider === 'yooda'">
                            {{(template.provider === 'yooda' ? $t(`component_planningScheduledReporting_scheduleConfigModelName_${template.name}`) : template.name)}}
                        </h6>

                        <div class="mt-4">

                            <div class="rounded-lg grey lighten-4 pa-2 mb-2" style="border: 1px dashed #d0d0d0 !important;">
                                <span
                                    v-html="$t('component_reportingTemplateList_placeholderTitle')
                                    .replace('[*COMPONENT-COUNT*]',template.componentCount)"
                                />
                            </div>

                            <div class="rounded-lg grey lighten-4 pa-2" style="border: 1px dashed #d0d0d0 !important; height: 150px;">
                                <span>{{ $t('component_reportingTemplateList_usageLabel') }}</span>

                                <ul class="mt-2" style="list-style: disc">
                                    <li v-for="(project, index) in projectUsed(template.id)" :key="index">{{ project }}</li>
                                </ul>
                            </div>
                        </div>

                        <v-btn class="mt-4" color="primary" style="width: 100%" @click="displayPreview(template.id)">{{ $t('component_reportingTemplateList_btn_preview') }}</v-btn>

                        <p class="mt-4 mb-0 text-center" v-if="!template.editable">{{ $t('component_reportingTemplateList_label_notEditable') }}</p>
                        <p v-else class="mt-4 mb-0 text-center primary--text cursor-pointer" @click="confirmEditTemplate(template)"><v-icon small>mdi-format-paint</v-icon> {{ $t('component_reportingTemplateList_label_editBtn') }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>



    </div>

    <reporting-preview-model ref="reportingPreview" :template-id="previewTemplateId" v-if="previewTemplateId && showPreview"/>



    <v-dialog
        v-model="displayConfirmEdit"
    >
        <v-card width="430px" v-if="displayConfirmEdit">
            <v-card-text class="pt-6 v-application">
                <h4 class="text-h4 text-center font-weight-medium mb-2 primary--text">{{ $t('component_reportingTemplateList_editConfirm_title') }}</h4>

                <p v-if="projectUsed(editTemplate.id).length ===1">{{ $t('component_reportingTemplateList_editConfirm_text_single') }}</p>
                <p v-else>{{ $t('component_reportingTemplateList_editConfirm_text') }}</p>


                <ul class="mt-2" style="list-style: disc">
                    <li v-for="(project, index) in projectUsed(editTemplate.id)" :key="index">{{ project }}</li>
                </ul>

                <div class="text-right mt-12">
                    <v-btn class="inline-block mr-4" outlined color="primary" @click="displayConfirmEdit=false">
                        {{ $t('component_reportingTemplateList_editConfirm_btnCancel') }}
                    </v-btn>
                    <v-btn class="inline-block" color="primary" @click="$emit('toggleMode',editTemplate.id)">
                        {{ $t('component_reportingTemplateList_editConfirm_btnOk') }}
                    </v-btn>
                </div>


            </v-card-text>
        </v-card>
    </v-dialog>
</div>

</template>


<script>


import templateApiCalls from "@/api/axios/dataReport/template";
import reportingApiCalls from "@/api/axios/dataReport/reporting";
import ReportingPreviewModel from "@/components/yooda-components/reporting/ReportingPreviewModel";
import Vue from "vue";


export default {
    data(){
        return{
            templateList:null,
            searchTemplate:null,
            filterFavorite:true,
            filterMyModels:true,
            filterYoodaModels:true,
            showPreview:false,
            previewTemplateId:null,
            computedReportingList:null,
            displayConfirmEdit:false,
            editTemplate:null,
        }
    },
    computed:{
        displayedTemplateList(){
            let filteredList = []
            if(this.templateList.data){
                this.templateList.data.forEach((data)=>{
                    let add = false
                    let name = (data.provider === 'yooda' ? this.$t(`component_planningScheduledReporting_scheduleConfigModelName_${data.name}`) : data.name)
                    if(!this.searchTemplate){
                        add = false
                        if(
                            (this.filterFavorite && data.favorite)||
                            (this.filterMyModels && data.provider === 'subscriber')||
                            (this.filterYoodaModels && data.provider === 'yooda')
                        ){
                            add=true
                        }
                    }
                    else{
                        if(this.searchTemplate){
                            if(
                                name.toLowerCase().includes(this.searchTemplate.toLowerCase()) && (
                                    (this.filterFavorite && data.favorite)||
                                    (this.filterMyModels && data.provider === 'subscriber')||
                                    (this.filterYoodaModels && data.provider === 'yooda')
                                )){
                                add = true
                            }
                        }
                    }
                    if(add){ filteredList.push(data) }
                })
            }
            return filteredList
        }
    },
    methods:{

        projectUsed(templateId){
            let displayUsedProject = (this.computedReportingList.data ? this.computedReportingList.data.filter(elem=>elem.template.id === templateId).map(elem=>{return elem.project.name}) : [''])
            let projectCount = displayUsedProject.length
            if(projectCount>6){
                displayUsedProject = displayUsedProject.slice(0,5)
                displayUsedProject.push(`...et ${projectCount-6} autres`)
            }
            return displayUsedProject

        },

        confirmEditTemplate(template){
            this.editTemplate = template
            if(this.projectUsed(template.id).length >0){
                this.displayConfirmEdit = true
            }
            else{
                this.$emit('toggleMode',template.id)
            }

        },
        displayPreview(templateId){
            this.showPreview = false

            setTimeout(() => {
                this.previewTemplateId = templateId
                this.showPreview = true
                Vue.nextTick(() => {
                    this.$refs.reportingPreview.showPreview()
                })

            }, 300)


        },
        updateFavorite(template, isFavorite){
            reportingApiCalls.updateSubscriptionMetaTemplate(template.subscriptionMetaTemplateId, {favorite:isFavorite}).then(()=>{
                this.templateList.data.forEach((item)=>{if(item.id === template.id){item.favorite = isFavorite}})
            })
        },

        initComponentData(){
            this.getComponentData({
                componentName:'computedReportingList',
                promise: reportingApiCalls.readAllReporting(this.$route.params.subscriptionId, false)
            }).then(()=>{
                this.getComponentData({
                    componentName:'templateList',
                    promise: templateApiCalls.readTemplatesBySubscriptionId(this.$route.params.subscriptionId)
                })
            })
        }
    },
    components:{
        ReportingPreviewModel
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>

<style lang="scss">

</style>
